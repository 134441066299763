import { ReactNode } from 'react';

export default function IconText(props: {
  icon: ReactNode;
  text: string;
  size?: 'large' | 'standard' | 'small' | 'xs';
  onClick?: () => void;
  right?: boolean;
}) {
  let textClass = 'transition break-all line-clamp-1';
  let wrapperClass = 'inline-flex items-center';
  let iconClass = 'transition inline-block flex-shrink-0';

  if (props.size !== 'small') {
    textClass += ' font-semibold';
  }

  iconClass += props.size === 'small' ? ' size-[12px]' : ' size-[16px]';

  const nonSmallText = props.size === 'large' ? 'text-lg' : 'text-base';

  if (props.right) {
    textClass += ' order-first';

    if (props.size === 'small') {
      textClass += ' text-sm mr-1';
    } else {
      textClass += ` mr-2 ${nonSmallText}`;
    }
  } else {
    if (props.size === 'small') {
      textClass += ' text-sm ml-1.5';
    } else {
      textClass += ` ml-2 ${nonSmallText}`;
    }
  }

  if (props.onClick) {
    wrapperClass += ' cursor-pointer group/icontext';
    textClass += ' group-hover/icontext:text-gray-400 group-hover/icontext:translate-x-0.5';
    iconClass += ' group-hover/icontext:text-gray-400';

    if (props.right) {
      iconClass += ' group-hover/icontext:translate-x-1 group-hover/icontext:scale-110';
    } else {
      iconClass += ' group-hover/icontext:scale-110';
      textClass += ' group-hover/icontext:scale-105';
    }
  }

  return (
    <span className={wrapperClass} onClick={props.onClick}>
      <span className={iconClass}>{props.icon}</span>
      <span className={textClass}>{props.text}</span>
    </span>
  );
}
